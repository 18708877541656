import React from "react";

const DashboardCard = ({
  color = "danger",
  header,
  text,
  value,
  textSecondary,
  valueSecondary,
  icon,
  textPosition = "text-center",
}) => {
  return (
    <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center align-items-center">
      <div
        className={`card text-white bg-${color} mb-3`}
        style={{ width: "100%" }}
      >
        <div className="card-header h4 text-center">{header}</div>
        <div className="card-body">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-2 text-center">
              <i className={`${icon} h2`}></i>
            </div>
            <div className={"col-10 " + textPosition}>
              {/* <h5 className="card-title">Danger card title</h5> */}
              <p className="card-text">
                <strong>{text}:</strong> {value}
                {textSecondary && (
                  <>
                    <br />
                    <strong>{textSecondary}:</strong> {valueSecondary}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
