import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import Navbar from "../Layouts/Navbar";
import Spinner from "../Layouts/Spinner";

// Custom Utility Helpers
import { setupDisplayDate } from "../utils/date";

const EditTodo = ({ match }) => {
  const [todo, setTodo] = useState({});
  const [displayDate, setDisplayDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { title, details, targetDate } = todo;

  useEffect(() => {
    preload();
    // eslint-disable-next-line
  }, []);

  // To set Display Date whenever there is a change in todo.displayDate - from user side
  useEffect(() => {
    setDisplayDate(setupDisplayDate(targetDate));
  }, [targetDate]);

  // Pre load data
  const preload = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/todo/${match.params.id}`);
      if (!res.data.details || res.data.details === "") {
        res.data.details = "N/A";
      }
      await setTodo(res.data);
      setDisplayDate(setupDisplayDate(res.data.targetDate));

      // await setdisplayDate(setupDisplayDate);
      setLoading(false);
      //
    } catch (error) {
      console.log(error.response);
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  // Edit Form
  const editForm = () => (
    <form onSubmit={onSubmit}>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={title}
          placeholder="Title of the Task"
          onChange={onChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="details" className="form-label">
          Details
        </label>
        {/* <input
          type="text"
          className="form-control"
          name="details"
          value={details}
          placeholder="Details for the Task"
          onChange={onChange}
          required
        /> */}

        <textarea
          name="details"
          value={details}
          placeholder="Details for the Task"
          onChange={onChange}
          className="form-control"
          aria-label="textarea"
          rows="5"
          required
        ></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="targetDate" className="form-label">
          Target Date
        </label>
        <input
          type="date"
          className="form-control"
          name="targetDate"
          value={displayDate !== null && displayDate !== "" && displayDate}
          placeholder="Target Date for the Task"
          onChange={onChange}
        />
      </div>
      <div className="d-grid gap-2 mb-5">
        <button
          type="submit"
          disabled={updating && true}
          className="btn btn-outline-warning"
        >
          {!updating ? (
            "Update Task"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Updating...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  // On Form Value Change
  const onChange = (e) => {
    setTodo({
      ...todo,
      [e.target.name]: e.target.value,
    });
  };

  // On Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    if (!todo.targetDate || todo.targetDate === "") {
      await delete todo.targetDate;
    }

    try {
      const res = await axios.put(`/api/todo/${todo._id}`, todo);
      setTodo(res.data);

      toast.success("Task has been updated successfully!");
      setUpdating(false);
      //
    } catch (error) {
      console.log(error.response);
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setUpdating(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container my-5">
        {loading === true ? (
          <Spinner />
        ) : (
          <>
            <div align="center">
              <h2>
                <span className="text-warning">Edit</span> a Todo{" "}
                <span className="text-warning">Task</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
              <div
                className="col-lg-6 col-md-8 col-sm-10 mt-3"
                // style={{ margin: "0 auto", width: "70%" }}
              >
                {editForm()}
              </div>
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditTodo;
