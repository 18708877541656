import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Utility
import setAuthToken from "../utils/setAuthToken";
import { isAuthenticated, isAdmin, signout } from "../utils/auth";

// Components
import ChangePasswordModal from "../Pages/Password/ChangePasswordModal";
import ChangeEmailModal from "../Pages/Email/ChangeEmailModal";
import { isOnline } from "../utils/checkOnline";

const Navbar = () => {
  const [user, setUser] = useState("");
  const [adminScreenSize, setAdminScreenSize] = useState("md");
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      setUser(isAuthenticated().user);
      isAdmin() && setAdminScreenSize("lg");
    } else {
      setUser({});
    }

    if (isOnline() === false) {
      history.push("/offline");
    }
    // eslint-disable-next-line
  }, []);

  // Setting Authorization header using setAuthToken(token) utility for Axios Requests
  if (window.location !== null && localStorage.getItem("tm")) {
    setAuthToken(JSON.parse(localStorage.getItem("tm")).token);
  }

  const publicLinks = () => (
    <>
      <li className="nav-item">
        <Link className="nav-link" to="/signup">
          <i className="bi bi-person-plus margin-r-_5"></i>
          Sign Up
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/signin">
          <i className="bi bi-box-arrow-in-right margin-r-_5"></i>
          Sign In
        </Link>
      </li>
    </>
  );

  const privateLinks = () => (
    <>
      {/* 
      <li className="nav-item">
        <Link className="nav-link" to="addnewtodo">
          Add New Todo Task
        </Link>
      </li> */}
      <li className="nav-item dropdown">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-list-task margin-r-_5"></i>
          Tasks
        </Link>
        <ul className="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
          <li className="nav-item">
            <Link className="dropdown-item nav-item-custom" to="/">
              <i className="bi bi-journal-text margin-r-_5"></i>
              View All
            </Link>
          </li>
          <li className="nav-item">
            <Link className="dropdown-item nav-item-custom" to="/todo/addnew">
              <i className="bi bi-clipboard-plus margin-r-_5"></i>
              Add New
            </Link>
          </li>
        </ul>
      </li>

      {/* This will be hidden in screens > md with expanded navbar as the profile avatar tab will have the signout option then. */}

      <hr className={`nav-item d-${adminScreenSize}-none`} />

      <li className={`nav-item d-${adminScreenSize}-none`}>
        <Link
          className="nav-link"
          to="#"
          onClick={() => {
            if (signout()) {
              history.push("/signin");
            }
          }}
        >
          <i className="bi bi-box-arrow-right margin-r-_5"></i>
          Sign Out
        </Link>
      </li>

      <hr className={`nav-item d-${adminScreenSize}-none`} />
    </>
  );

  const avatar = () => (
    <>
      <li className="nav-item dropdown">
        {/* Showing Avatar with Name Initial for md and large (when navbar expands) and hiding for small */}
        <Link
          to="#"
          className={`d-none d-${adminScreenSize}-block nav-link text-center align-items-center rounded rounded-circle bg-secondary-custom text-light`}
          id="navbarDropdown"
          style={{ height: "2.5rem", width: "2.5rem" }}
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {user.firstName && user.firstName.charAt(0)}
        </Link>

        {/* Showing 'Profile' as Dropdown menu instead of Avatar for screens <md when navbar shrinks */}
        <Link
          to="#"
          className={`d-block d-${adminScreenSize}-none nav-link dropdown-toggle`}
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-person-circle margin-r-_5"></i>
          Profile
        </Link>

        <ul
          className="dropdown-menu bg-dark profile-box-custom"
          aria-labelledby="navbarDropdown"
        >
          <li className="nav-item nav-item-avatar-custom align-items-center">
            <i className="bi bi-person"></i>:
            <span className="">
              {" "}
              {user && user.firstName} {user && user.lastName && user.lastName}
            </span>
          </li>
          <li className="nav-item nav-item-avatar-custom align-items-center">
            <i className="bi bi-envelope-open"></i> :
            <span className=""> {user && user.email}</span>
          </li>
          <li className="nav-item nav-item-avatar-custom align-items-center">
            <i className="bi bi-clock"></i>{" "}
            <span className=""> TimeZone : {user && user.timezone}</span>
          </li>

          <hr className="text-secondary" />

          <li className="nav-item">
            <Link
              className="dropdown-item nav-item-custom"
              data-bs-toggle="modal"
              data-bs-target="#changeEmailModalId"
              to="#"
            >
              <i className="bi bi-envelope margin-r-_5"></i>
              Change Email ID
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="dropdown-item nav-item-custom"
              data-bs-toggle="modal"
              data-bs-target="#changePasswordModalId"
              to="#"
            >
              <i className="bi bi-shield-lock margin-r-_5"></i>
              Change Password
            </Link>
          </li>

          <hr className="text-secondary" />

          {/* This signout inside the profile will be hidden for <md screens as there is a separate logout() in private links */}
          <li className="nav-item">
            <Link
              className={`d-none d-${adminScreenSize}-block dropdown-item nav-item-custom`}
              to="#"
              onClick={() => {
                if (signout()) {
                  history.push("/signin");
                }
              }}
            >
              <i className="bi bi-box-arrow-right margin-r-_5"></i>
              Sign Out
            </Link>
          </li>
        </ul>
      </li>

      {navSeparator()}
    </>
  );

  const navSeparator = () => (
    <li className={`nav-item d-none d-${adminScreenSize}-block`}>
      <span className="nav-link">|</span>
    </li>
  );

  return (
    <>
      <nav
        className={`navbar navbar-expand-${adminScreenSize} navbar-dark bg-dark sticky-top align-items-center justify-content-center`}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <span className="pride-banner">
              <i className="bi bi-clipboard-check margin-r-_5"></i>
              Task Management
            </span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Using this following line to separate the menu to right */}
            <span className="me-auto"></span>

            <ul className={`navbar-nav mb-2 mb-${adminScreenSize}-0`}>
              {isAuthenticated() && avatar()}

              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <i className="bi bi-house-door margin-r-_5"></i>
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  <i className="bi bi-file-person margin-r-_5"></i>
                  About
                </Link>
              </li>

              {isAuthenticated() ? privateLinks() : publicLinks()}

              {isAuthenticated() && isAdmin() && (
                <>
                  {navSeparator()}
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/dashboard">
                      <i className="bi bi-menu-app margin-r-_5"></i>
                      Admin
                    </Link>
                  </li>
                </>
              )}

              {navSeparator()}

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://github.com/arijitcodes/task-management-v1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-github margin-r-_5"></i>
                  GitHub
                  {/* <i className="bi bi-link-45deg margin-l-_5"></i> */}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Pride Banner */}
      <div className="bg-dark d-flex justify-content-center text-center py-2">
        <div
          className={`row container-md justify-content-center py-custom-_5 ${
            isAuthenticated() && "pride-border"
          }`}
        >
          <div className="col w-100 pride-banner-text">
            {/* <span className="pride"># W A L K W I T H P R I D E</span> */}
            <span className="pride-banner"># walk with pride</span> 🌈 🏳‍🌈
          </div>
        </div>
      </div>

      {/* Change Email ID Modal */}
      <ChangeEmailModal />

      {/* Change Password Modal */}
      <ChangePasswordModal />
    </>
  );
};

export default Navbar;
