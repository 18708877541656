import React from "react";

// Components
import Navbar from "../Layouts/Navbar";

const About = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid my-4">
        <h1 className="text-white">About Task Management App</h1>
        <p className="my-1 text-secondary">
          This is a Full Stack Task Management App to manage all of your Tasks
          and Todo Elements at one place.
        </p>
        <div className="my-5">
          <h3>Technologies Used:</h3>
          <div className="ml-2 mt-4">
            <h5>Backend (REST API):</h5>
            <p className="text-secondary">
              Node, Express, Mongoose, MongoDB, Dotenv, Express Validator, JWT
              (jsonwebtoken), BCryptJS, Node-Cron, Nodemailer, Sendgrid
              (Twillio) Email Service, Morgan, Helmet
            </p>
          </div>
          <div className="ml-2 mt-3">
            <h5>Frontend:</h5>
            <p className="text-secondary">
              React, React Hooks, React Router Dom, Axios, Bootstrap 5, React
              Toastify, Bootstrap Icons
            </p>
          </div>
        </div>
        <div className="ml-2 d-flex flex-row align-items-center justify-content-start">
          <h6 className="margin-r-_5">Author:</h6>
          <h6>
            <a
              href="https://github.com/ArijitCodes"
              target="_blank"
              rel="noreferrer"
              className="no-underline"
            >
              Arijit Banerjee
            </a>
          </h6>
        </div>
        <p className="bg-dark text-secondary p-2 mt-4">
          <strong>Version: </strong>1.0.3
        </p>
      </div>
    </>
  );
};

export default About;
