import { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom Private Route Component
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import AdminRoute from "./components/Routes/AdminRoute";

// Custom Utility Component
import { signout } from "./components/utils/auth";
import { isOnline } from "./components/utils/checkOnline";

// Components
import Home from "./components/Pages/Home";
import Signin from "./components/Pages/Signin";
import Signup from "./components/Pages/Signup";
import AddNewTodo from "./components/Pages/AddNewTodo";
import Error404 from "./components/Pages/Error404";
import EditTodo from "./components/Pages/EditTodo";
import About from "./components/Pages/About";
import EmailVerification from "./components/Pages/Email/EmailVerification";
import ForgotPassword from "./components/Pages/Password/ForgotPassword";
import ResetPassword from "./components/Pages/Password/ResetPassword";
import ResetEmail from "./components/Pages/Email/ResetEmail";
import Offline from "./components/Pages/Offline";

// Admin Components
import AdminDashboard from "./components/Admin/AdminDashboard";
import ManageUsers from "./components/Admin/Users/ManageUsers";

function App() {
  const [redirect, setRedirect] = useState(false);
  const [offline, setOffline] = useState(false);

  const performRedirect = () => {
    if (redirect) {
      return <Redirect to="/signin" />;
    }
  };

  // Offline Page Redirect
  const offlineRedirect = () => {
    if (offline) {
      return <Redirect to="/offline" />;
    }
  };

  // Axios Interceptor, handling if Offline before req
  axios.interceptors.request.use(
    (request) => {
      if (isOnline() === true) {
        return request;
      } else {
        setOffline(true);
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Axios Interceptor, handling Token Expiry signout
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.signout) {
        error.response.data.error =
          "Your session has Expired! Please log in again to continue!";
        signout();

        setRedirect(true);
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route
            exact
            path="/email/verification/:token"
            component={EmailVerification}
          />
          <Route exact path="/email/change/:token" component={ResetEmail} />
          <PublicRoute exact path="/signup" component={Signup} />
          <PublicRoute exact path="/signin" component={Signin} />
          <PublicRoute
            exact
            path="/password/forgot"
            component={ForgotPassword}
          />
          <PublicRoute
            exact
            path="/password/reset/:token"
            component={ResetPassword}
          />
          <PrivateRoute exact path="/todo/addnew" component={AddNewTodo} />
          <PrivateRoute exact path="/todo/edit/:id" component={EditTodo} />

          <AdminRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminRoute
            exact
            path="/admin/users/manage"
            component={ManageUsers}
          />

          <Route exact path="/offline" component={Offline} />

          <Route path="*" component={Error404} />
        </Switch>
        {performRedirect()}
        {offlineRedirect()}
      </Router>

      <ToastContainer position="bottom-right" pauseOnHover={false} />
    </>
  );
}

export default App;
