import React, { useState } from "react";

// Components
import Navbar from "../Layouts/Navbar";
import AdminSidebar from "../Layouts/AdminSidebar";

const AdminBase = ({ children }) => {
  const [sidebar, setSidebar] = useState(
    window.screen.width >= 780 ? true : false
  );

  return (
    <>
      <Navbar />
      <div
        className="d-flex w-100 h-100"
        style={{
          maxWidth: "100vw",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 128px)",
        }}
      >
        {/* Sidebar */}
        <div className={sidebar ? "box2" : "hidden2"}>
          <AdminSidebar />
        </div>

        <div className="container-fluid overflow-auto">
          {/* Admin Menu Button */}
          <div className="mx-2 mt-2 fw-bolder text-white fs-1">
            <i
              className="bi bi-list admin-nav-button"
              onClick={(e) => setSidebar((prev) => !prev)}
            ></i>
          </div>

          {/* Admin Body */}
          <div className="container-md">{children}</div>
        </div>
      </div>
    </>
  );
};

export default AdminBase;
