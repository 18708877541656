import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Components
import Navbar from "../../Layouts/Navbar";
import Illustration from "../../Assets/illustration-forgot-password.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle Change
  const onChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post("/api/password/forgot", { email });
      setEmail("");
      toast.success(res?.data?.msg);
      setLoading(false);
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  // Forgot Password Form
  const forgotPasswordForm = () => (
    <form onSubmit={onSubmit}>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={email}
          placeholder="You Email id"
          onChange={onChange}
          required
        />
      </div>

      <hr />

      <div className="d-grid gap-2 mb-2">
        <button
          type="submit"
          disabled={loading && true}
          className="btn btn-outline-warning"
        >
          {!loading ? (
            "Submit Email"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Loading...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="row align-items-center">
          <div className="text-center col col-md-8 col-lg-6 offset-md-2 offset-lg-0 mt-3 d-none d-md-block">
            <img
              src={Illustration}
              alt="Forgot Password Illustration"
              className="mx-auto d-block"
              style={{ maxWidth: "90%" }}
            />
          </div>

          <div className="col-lg-6 col-sm-10 offset-sm-1 offset-lg-0 mt-3 mt-md-5 mt-lg-3">
            <div align="center">
              <h2>
                Forgot <span className="text-warning">Password</span> ?
              </h2>
              <p className="text-secondary mt-4">
                Enter your email address.
                <br />
                We will send you a Password Recovery link.
              </p>
            </div>
            {forgotPasswordForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
