import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// Components
import Navbar from "../../Layouts/Navbar";
import Illustration from "../../Assets/illustration-reset-password.svg";

const ResetPassword = ({ match }) => {
  const [passwords, setPasswords] = useState({
    password: "",
    password2: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { password, password2 } = passwords;

  // Handle Change
  const onChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  // Handle Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Checking if two passwords match
    if (password !== password2) {
      setLoading(false);
      return toast.error("Passwords do not match! Please try again!");
    }

    try {
      const res = await axios.post("/api/password/reset", {
        token: match.params.token,
        password,
        password2,
      });

      setPasswords({
        ...passwords,
        password: "",
        password2: "",
      });

      toast.success(res.data.msg);
      setLoading(false);
      history.push("/signin");
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  // Reset Password Form
  const resetPasswordForm = () => (
    <form onSubmit={onSubmit}>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Your New Password
        </label>
        <input
          type="password"
          className="form-control"
          name="password"
          value={password}
          placeholder="Your New Password"
          minLength="5"
          onChange={onChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password2" className="form-label">
          Confirm New Password
        </label>
        <input
          type="password"
          className="form-control"
          name="password2"
          value={password2}
          placeholder="Please confirm the same new password"
          minLength="5"
          onChange={onChange}
          required
        />
      </div>

      <hr />

      <div className="d-grid gap-2 mb-4">
        <button
          type="submit"
          disabled={loading && true}
          className="btn btn-outline-warning"
        >
          {!loading ? (
            "Reset Password"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Loading... Please Wait...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row align-items-center mt-4">
          <div className="text-center col col-md-8 col-lg-6 offset-md-2 offset-lg-0 mt-3 d-none d-md-block d-lg-none">
            <img
              src={Illustration}
              className="w-100 h-100"
              alt="Reset Password Illustration"
            />
          </div>

          <div className="col-lg-6 col-sm-10 offset-sm-1 offset-lg-0 mt-4 mt-md-3 mt-lg-5">
            <div className="my-4" align="center">
              <h2>
                Reset <span className="text-warning">Password</span>
              </h2>
              <span className="text-secondary">Enter your new password</span>
            </div>
            {resetPasswordForm()}
          </div>

          <div className="text-center col-lg-6 mt-4 mt-md-3 mt-lg-5 d-none d-lg-block">
            <img
              src={Illustration}
              className="w-100 h-100"
              alt="Reset Password Illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
