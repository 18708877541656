import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// COmponents
import Navbar from "../../Layouts/Navbar";

const ResetEmail = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!match.params.token) {
      history.push("/");
    } else {
      setLoading(true);
      verify();
    }
    // eslint-disable-next-line
  }, []);

  // Email Verify
  const verify = async () => {
    try {
      const res = await axios.post(`/api/email/reset`, {
        token: match.params.token,
      });

      // If user is signed in, change email id in Local Token
      if (localStorage.getItem("tm")) {
        let localToken = await JSON.parse(localStorage.getItem("tm"));
        localToken.user.email = await res.data.newEmail;
        await localStorage.setItem("tm", JSON.stringify(localToken));
      }

      setLoading(false);
      toast.success(res.data.msg);
      history.push("/");
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      history.push("/");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4 text-white">
        <div className="row">
          {loading && (
            <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1 mt-5">
              <div className="alert alert-info">
                <div className="alert-heading h3 fw-bold d-flex align-items-center">
                  <i className="bi bi-info-circle margin-r-_5"></i>
                  <span className="">New Email ID Verification</span>

                  <div className="spinner-border margin-l-_5" role="status">
                    <span className="visually-hidden">Verifying...</span>
                  </div>
                </div>
                <hr />
                <p>We are verifying your new email... Please Wait...!!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetEmail;
