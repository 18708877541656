import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Component
import Navbar from "../Layouts/Navbar";
import TodoItem from "./TodoItem";
import Spinner from "../Layouts/Spinner";
import IllustrationTaskList from "../Assets/illustration-task-list.svg";

const Home = () => {
  const [todos, setTodos] = useState([]);
  const [timer, setTimer] = useState(10);
  const [activateTimer, setActivateTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [due, setDue] = useState([]);
  const [today, setToday] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);

  const history = useHistory();

  useEffect(() => {
    preload();
    // eslint-disable-next-line
  }, [reload]);

  // Countdown Timer to auto redirect if no tasks found
  useEffect(() => {
    const tick = 1000;
    const countdown = setTimeout(() => {
      if (todos.length >= 1 || activateTimer === false) {
        setActivateTimer(false);
        clearTimeout(countdown);
      } else {
        setTimer(timer - 1);
      }
    }, tick);

    return () => {
      if (timer === 1) {
        history.push("/todo/addnew");
      } else {
        clearTimeout(countdown);
      }
    };
    // eslint-disable-next-line
  }, [activateTimer, timer]);

  const preload = async () => {
    setLoading(true);

    const newDue = [];
    const newToday = [];
    const newUpcoming = [];
    const newCompleted = [];

    try {
      const res = await axios.get("/api/todo");
      await setTodos(res.data.reverse());

      await res.data.map(async (todo) => {
        const currentDate = await new Date().toDateString();
        const tDate = await new Date(todo.targetDate).toDateString();

        // Taking the difference
        const dateDifference = await (new Date(currentDate) - new Date(tDate));

        if (todo.completed === true) {
          /* completed.length > 0
            ? await setCompleted([...completed, todo])
            : await setCompleted([todo]); */
          newCompleted.push(todo);
        } else {
          if (dateDifference === 0) {
            newToday.push(todo);
          } else if (dateDifference > 0) {
            newDue.push(todo);
          } else {
            newUpcoming.push(todo);
          }
        }
      });

      await setDue(newDue);
      await setToday(newToday);
      await setUpcoming(newUpcoming);
      await setCompleted(newCompleted);

      setLoading(false);

      // Checking if we dont have any todos, then activating Countdown to redirect
      if (!loading && res.data.length < 1) {
        setActivateTimer(true);
      }
      //
    } catch (error) {
      // Check for NO Tasks Error
      if (error?.response?.status === 404 && todos.length === 0) {
        setActivateTimer(true);
      }

      // General Error Toast Msg
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
    //
  };

  const changeReload = () => {
    setTodos([]);
    setReload(!reload);
  };

  return (
    <>
      <Navbar />
      <div className="bg-dark container-fluid d-flex justify-content-center align-items-center">
        <div className="row align-items-center container-md bg-dark m-0 p-0 p-2">
          <div className="col-6 py-3">
            <div className="h1">Manage all of your tasks at one place.</div>
            <div className="mt-4 d-none d-sm-block">
              <Link
                to="/todo/addnew"
                className="btn btn-violet-custom rounded btn-block px-4"
              >
                Add A New Task
              </Link>
            </div>
          </div>
          <div className="col-6 text-center">
            <img
              src={IllustrationTaskList}
              className="m-2 w-100"
              alt="Illustration of Task List"
            />
          </div>
        </div>
      </div>

      <div className="container-md">
        {/* <h1>Home</h1> */}

        <div className="row">
          {loading && todos.length <= 0 && <Spinner />}
          {!loading && todos && todos.length === 0 && activateTimer && (
            <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1 mt-5">
              <div className="alert alert-warning">
                <div className="alert-heading h3 fw-bold">
                  <i className="bi bi-exclamation-triangle margin-r-_5"></i>
                  <span className="">No Tasks Found!</span>
                </div>
                <p>
                  <Link to="/todo/addnew" className="alert-link">
                    Click Here
                  </Link>{" "}
                  to add a new Task!
                </p>
                <hr />
                Or, you will be automatically redirected in{" "}
                <span className="fw-bold">{timer}</span> seconds!
              </div>
            </div>
          )}

          {/* {todos &&
            todos.length > 0 &&
            todos.map((todo) => (
              <TodoItem
                key={todo._id}
                todo={todo}
                changeReload={changeReload}
              />
            ))} */}
        </div>

        {todos && todos.length > 0 && (
          <>
            <div
              className={`${
                due.length > 0 ? "row my-2 p-2 bg-dark" : "d-none"
              }`}
            >
              {/* Due Tasks */}
              <h3 className="my-2 mb-3 text-center text-sm-start">
                Due Tasks:
              </h3>
              {due &&
                due.length > 0 &&
                due.map((todo) => {
                  const currentDate = new Date().toDateString();
                  const tDate = new Date(todo.targetDate).toDateString();

                  // Taking the difference
                  const dateDifference =
                    new Date(currentDate) - new Date(tDate);

                  return (
                    !todo.completed &&
                    dateDifference > 0 && (
                      <TodoItem
                        key={todo._id}
                        todo={todo}
                        changeReload={changeReload}
                      />
                    )
                  );
                })}
            </div>

            <div
              className={`${
                today.length > 0 ? "row my-2 p-2 bg-dark" : "d-none"
              }`}
            >
              {/* Today's Tasks */}
              <h3 className="my-2 mb-3 text-center text-sm-start">
                Today's Tasks:
              </h3>
              {today &&
                today.length > 0 &&
                today.map((todo) => {
                  const currentDate = new Date().toDateString();
                  const tDate = new Date(todo.targetDate).toDateString();

                  // Taking the difference
                  const dateDifference =
                    new Date(currentDate) - new Date(tDate);

                  return (
                    !todo.completed &&
                    dateDifference === 0 && (
                      <TodoItem
                        key={todo._id}
                        todo={todo}
                        changeReload={changeReload}
                      />
                    )
                  );
                })}
            </div>

            <div
              className={`${
                upcoming.length > 0 ? "row my-2 p-2 bg-dark" : "d-none"
              }`}
            >
              {/* Upcoming Tasks */}
              <h3 className="my-2 mb-3 text-center text-sm-start">
                Upcoming Tasks:
              </h3>
              {upcoming &&
                upcoming.length > 0 &&
                upcoming.map((todo) => {
                  const currentDate = new Date().toDateString();
                  const tDate = new Date(todo.targetDate).toDateString();

                  // Taking the difference
                  const dateDifference =
                    new Date(currentDate) - new Date(tDate);

                  return (
                    !todo.completed &&
                    dateDifference < 0 && (
                      <TodoItem
                        key={todo._id}
                        todo={todo}
                        changeReload={changeReload}
                      />
                    )
                  );
                })}
            </div>

            <div
              className={`${
                completed.length > 0 ? "row my-2 p-2 bg-dark" : "d-none"
              }`}
            >
              {/* Completed Tasks */}
              <h3 className="my-2 mb-3 text-center text-sm-start">
                Completed Tasks:
              </h3>
              {completed &&
                completed.length > 0 &&
                completed.map(
                  (todo) =>
                    todo.completed && (
                      <TodoItem
                        key={todo._id}
                        todo={todo}
                        changeReload={changeReload}
                      />
                    )
                )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
