import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

// Components
import Navbar from "../Layouts/Navbar";
import IllustrationAddTask from "../Assets/illustration-add-task.svg";

// Cusstom Utility
import { setupDisplayDate } from "../utils/date";

const AddNewTodo = () => {
  const [todo, setTodo] = useState({
    title: "",
    details: "",
    targetDate: setupDisplayDate(new Date()),
  });
  const [loading, setLoading] = useState(false);

  const { title, details, targetDate } = todo;

  const addNewForm = () => (
    <form onSubmit={onSubmit}>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={title}
          placeholder="Title of the Task"
          onChange={onChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="details" className="form-label">
          Details
        </label>
        {/* <input
          type="text"
          className="form-control"
          name="details"
          value={details}
          placeholder="Details for the Task"
          onChange={onChange}
          required
        /> */}

        <textarea
          name="details"
          value={details}
          placeholder="Details for the Task"
          onChange={onChange}
          className="form-control"
          aria-label="textarea"
          rows="5"
          required
        ></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="targetDate" className="form-label">
          Target Date
        </label>
        <input
          type="date"
          className="form-control"
          name="targetDate"
          value={targetDate}
          placeholder="Target Date for the Task"
          onChange={onChange}
        />
        <div id="emailHelp" className="form-text">
          * You can leave it if the task is for Today.
        </div>
      </div>
      <div className="d-grid gap-2 mb-5">
        <button
          type="submit"
          disabled={loading && true}
          className="btn btn-outline-warning"
        >
          {!loading ? (
            "Add New Task"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Loading...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  // On Form Value Change
  const onChange = (e) => {
    setTodo({
      ...todo,
      [e.target.name]: e.target.value,
    });
  };

  // On Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!todo.targetDate || todo.targetDate === "") {
      await delete todo.targetDate;
    }

    try {
      await axios.post("/api/todo", todo);
      toast.success("New Todo Task has been added successfully!");

      setTodo({
        ...todo,
        title: "",
        details: "",
        targetDate: setupDisplayDate(new Date()),
      });

      setLoading(false);
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      // console.log(error.response);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row align-items-center">
          <div className="text-center col-md-8 col-lg-6 offset-md-2 offset-lg-0 mt-3 d-none d-md-block d-lg-none">
            <img
              src={IllustrationAddTask}
              className="w-75"
              alt="Illustration for Add New Task"
            />
          </div>

          <div className="col-lg-6 col-sm-10 offset-lg-0 offset-sm-1 mt-4 mt-md-4 mt-lg-5">
            <div align="center">
              <h2>
                Add a <span className="text-warning">New Task</span>
              </h2>
            </div>
            {addNewForm()}
          </div>

          <div className="text-center col-lg-6 d-none d-lg-block">
            <img
              src={IllustrationAddTask}
              className="w-100"
              alt="Illustration for Add New Task"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewTodo;
