import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

// Components
import Navbar from "../Layouts/Navbar";
import Illustration2 from "../Assets/illustration-02.svg";

// Utils
import setAuthToken from "../utils/setAuthToken";

const Signin = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { email, password } = user;

  const signinForm = () => (
    <form onSubmit={onSubmit}>
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <div className="mb-3 input-group">
        <input
          type="email"
          className="form-control"
          name="email"
          value={email}
          placeholder="You Email id"
          onChange={onChange}
          required
        />

        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-envelope"></i>
        </span>
      </div>

      <label htmlFor="password" className="form-label">
        Password
      </label>
      <div className="mb-3 input-group">
        <input
          type="password"
          className="form-control"
          name="password"
          value={password}
          placeholder="Your Password"
          minLength="5"
          onChange={onChange}
          required
        />

        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-shield-lock"></i>
        </span>
      </div>

      <div className="mt-4">
        <div className="text-end" style={{ marginTop: "1rem" }}>
          <Link to="/password/forgot" className="no-underline-muted">
            Forgot Password ? Click here to Reset.
          </Link>
        </div>
        <div className="text-end">
          <Link to="/signup" className="no-underline-muted">
            Don't have an account ? Click here to Sign Up.
          </Link>
        </div>
      </div>

      <hr />

      <div className="d-grid gap-2 mb-2">
        <button
          type="submit"
          disabled={loading && true}
          className="btn btn-outline-warning"
        >
          {!loading ? (
            "Sign In"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Loading...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  // On Form Value Change
  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  // On Form Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res = await axios.post("/api/auth/signin", user);
      const token = res.data.token;

      // Setting Auth Token manually to get the user and set payload in local
      setAuthToken(token);

      // setAuthToken(token);
      res = await axios.get("/api/user");

      // Create Payload for localstorage
      const payload = {
        token,
        user: {
          id: res.data._id,
          role: res.data.role,
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          timezone: res.data.timezone,
        },
      };

      // Set Payload in LocalStorage
      await localStorage.setItem("tm", JSON.stringify(payload));
      toast.success("Login Success");
      setLoading(false);
      if (res.data.role !== 1) {
        history.push("/");
      } else {
        history.push("/admin/dashboard");
      }
      //
    } catch (error) {
      // console.log(error.response);
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="row align-items-center">
          <div className="text-center col col-md-8 col-lg-6 offset-md-2 offset-lg-0 mt-3 d-none d-md-block">
            <img
              src={Illustration2}
              alt="Sign In"
              className="mx-auto d-block"
            />
          </div>

          <div className="col-lg-6 col-sm-10 offset-sm-1 offset-lg-0 mt-3 mt-md-5 mt-lg-3">
            <div align="center">
              <h2>
                Account <span className="text-warning">Sign In</span>
              </h2>
            </div>
            {signinForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
