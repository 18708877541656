import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

// Components
import Navbar from "../Layouts/Navbar";
import Illustration1 from "../Assets/illustration-01.svg";

const Signup = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
    timezone: "",
  });
  const [loading, setLoading] = useState(false);
  const [allTimezones, setAllTimezones] = useState([]);
  const history = useHistory();

  const { firstName, lastName, email, password, password2, timezone } = user;

  useEffect(() => {
    preload();
  }, []);

  const preload = async () => {
    const res = await axios.get("http://worldtimeapi.org/api/timezone");
    setAllTimezones(res.data);

    const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!res.data.includes(usersTimezone)) {
      let temp = [usersTimezone, ...res.data];
      setAllTimezones(temp.sort());
    }
  };

  const signupForm = () => (
    <form onSubmit={onSubmit}>
      <label htmlFor="firstName" className="form-label">
        First Name
      </label>
      <div className="mb-3 input-group">
        <input
          type="text"
          className="form-control"
          name="firstName"
          value={firstName}
          placeholder="You First Name"
          onChange={onChange}
          required
        />
        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-person-fill"></i>
        </span>
      </div>

      <label htmlFor="lastName" className="form-label">
        Last Name
      </label>
      <div className="mb-3 input-group">
        <input
          type="text"
          className="form-control"
          name="lastName"
          value={lastName}
          placeholder="You Last Name"
          onChange={onChange}
          required
        />
        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-person-badge"></i>
        </span>
      </div>

      <label htmlFor="email" className="form-label">
        Email
      </label>
      <div className="mb-3">
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            name="email"
            value={email}
            placeholder="Eg: email@domain.name"
            onChange={onChange}
            required
          />
          <span className="input-group-text bg-transparent text-white">
            <i className="bi bi-envelope"></i>
          </span>
        </div>
        <div id="emailHelp" className="form-text">
          Please make sure to use your actual and valid email id.
          <br />
          We'll never share your email with anyone else.
        </div>
      </div>

      <label htmlFor="password" className="form-label">
        Password
      </label>
      <div className="mb-3 input-group">
        <input
          type="password"
          className="form-control"
          name="password"
          value={password}
          placeholder="Your Password"
          minLength="5"
          onChange={onChange}
          required
        />
        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-shield-lock"></i>
        </span>
      </div>

      <label htmlFor="password2" className="form-label">
        Confirm Password
      </label>
      <div className="mb-3 input-group">
        <input
          type="password"
          className="form-control"
          name="password2"
          value={password2}
          placeholder="Please confirm the same password"
          minLength="5"
          onChange={onChange}
          required
        />
        <span className="input-group-text bg-transparent text-white">
          <i className="bi bi-shield-lock"></i>
        </span>
      </div>

      <label htmlFor="timezone" className="form-label">
        TimeZone
      </label>
      <div className="mb-3">
        <div className="input-group">
          <select
            className="form-select form-control"
            name="timezone"
            onChange={onChange}
            aria-label="Select TimeZone"
            required
          >
            <option value="">-- Select Your TimeZone --</option>
            {allTimezones?.map((tz, index) => (
              <option key={index} value={tz}>
                {tz}
              </option>
            ))}
          </select>
          <span className="input-group-text bg-transparent text-white">
            <i className="bi bi-clock"></i>
          </span>
        </div>

        <div id="timezoneHelp" className="form-text">
          Please select your actual TimeZone. This is for your Daily Task
          Reminder System.
          <br />
          You will recieve the reminders at 6 AM of the TimeZone that you
          select.
        </div>
      </div>

      <div className="mt-4">
        <div className="text-end">
          <Link to="/signin" className="no-underline-muted">
            Already have an account ? Click here to Sign In.
          </Link>
        </div>
      </div>

      <hr />

      <div className="d-grid gap-2 mb-4">
        <button
          type="submit"
          disabled={loading && true}
          className="btn btn-outline-warning"
        >
          {!loading ? (
            "Sign Up"
          ) : (
            <span>
              <i
                className="margin-r-_5 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></i>
              Loading...
            </span>
          )}
        </button>
      </div>
    </form>
  );

  // On Form Value Change
  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  // On Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for Password Mismatch
    if (password !== password2) {
      toast.error("Password Mismatch! Please try again!");
      setLoading(false);
      return;
    }

    // Check for Timezone Selection
    if (timezone === "" || !timezone) {
      toast.error("Please select your TimeZone!");
      setLoading(false);
      return;
    }

    // Register User
    const newUser = { firstName, lastName, email, password, timezone };

    try {
      const res = await axios.post("/api/auth/signup", newUser);
      // console.log(res);

      toast.success(res.data.msg);
      setLoading(false);
      history.push("/signin");

      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      // console.log(error.response);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row align-items-center">
          <div className="text-center col col-md-8 col-lg-6 offset-md-2 offset-lg-0 mt-3 d-none d-md-block d-lg-none">
            <img src={Illustration1} alt="Sign Up Illustration" />
          </div>

          <div className="col-lg-6 col-sm-10 offset-sm-1 offset-lg-0 mt-4 mt-md-3 mt-lg-5">
            <div className="mb-4" align="center">
              <h2>
                Account <span className="text-warning">Registration</span>
              </h2>
            </div>
            {signupForm()}
          </div>

          <div className="text-center col-lg-6 mt-3 d-none d-lg-block">
            <img src={Illustration1} alt="Sign Up Illustration" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
