import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Components
import AdminBase from "./AdminBase";
import DashboardCard from "../Layouts/DashboardCard";
import Spinner from "../Layouts/Spinner";

const AdminDashboard = () => {
  const [data, setData] = useState({
    numberOfTasks: null,
    numberOfUsers: null,
    taskReminderStats: {
      emailsSent: null,
      lastRunTime: null,
      lastRunTimeFinal: null,
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    preload();
  }, []);

  // Preload Admin Dashboard Items Data
  const preload = async () => {
    setLoading(true);
    try {
      //  Fetch Data
      const res = await axios.get("/api/admin/dashboard");
      let temp = { ...res.data };

      // Setup Last Run Time Date & Time format to Display
      const targetDate = new Date(res.data.taskReminderStats.lastRunTime);
      let dd = targetDate.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      let mm = targetDate.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      let yyyy = targetDate.getFullYear();

      // Set the Formatted Date and Time in temp obj
      temp.taskReminderStats.lastRunTimeFinal =
        dd +
        "/" +
        mm +
        "/" +
        yyyy +
        " - " +
        targetDate.toLocaleString([], {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });

      // Set 'data' State
      setData(temp);
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg ||
          error?.response?.data?.error ||
          "Unknown Internal Error!"
      );
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminBase>
      <div className="my-4">
        <div className="mb-4">
          <h3 className="text-center">Admin Dashboard</h3>
        </div>

        <div className="row my-2 mb-4 mt-5">
          {loading === true ? (
            <Spinner />
          ) : (
            <>
              <DashboardCard
                color="danger"
                header="Users"
                text="No. of Users"
                value={data.numberOfUsers}
                icon="bi bi-people"
              />
              <DashboardCard
                color="info"
                header="Tasks"
                text="No. of Tasks in DB"
                value={data.numberOfTasks}
                icon="bi bi-list-check"
              />
              <DashboardCard
                color="success"
                header="Task Reminder Stats"
                text="Last Run Time (DD/MM/YYYY)"
                value={data.taskReminderStats.lastRunTimeFinal}
                textSecondary="Task Reminder Emails Sent"
                valueSecondary={data.taskReminderStats.emailsSent}
                textPosition="text-left"
                icon="bi bi-envelope"
              />
            </>
          )}
        </div>
      </div>
    </AdminBase>
  );
};

export default AdminDashboard;
