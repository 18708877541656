// isAuthenticated will check if user is logged in or not
export const isAuthenticated = () => {
  if (localStorage.getItem("tm")) {
    return JSON.parse(localStorage.getItem("tm"));
  } else {
    return false;
  }
};

// isAdmin
export const isAdmin = () => {
  if (JSON.parse(localStorage.getItem("tm")).user.role === 1) {
    return JSON.parse(localStorage.getItem("tm")).user.id;
  } else {
    return false;
  }
};

// Sign Out User
export const signout = () => {
  if (localStorage.getItem("tm")) {
    localStorage.removeItem("tm");
    return true;
  }
};
