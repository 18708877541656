import React, { useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ChangePasswordModal = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const modal = useRef(null);

  const { email, password } = userData;

  // Handle Change
  const onChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle Submit
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email.trim() === "" || password.trim() === "") {
      setLoading(false);
      return toast.error(
        "Please input your current password and your new email id!"
      );
    }

    try {
      const res = await axios.post("/api/email/change", userData);

      setUserData({
        email: "",
        password: "",
      });

      await toast.success(res.data.msg);
      setLoading(false);
      modal.current.click();
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  return (
    <>
      {/* Modal */}
      <div
        className="modal fade"
        id="changeEmailModalId"
        tabIndex="-1"
        aria-labelledby="changeEmailModalIdLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-dark">
            <div className="modal-header text-white">
              <h5 className="modal-title" id="changeEmailModalIdLabel">
                Change Your Email ID
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-4">
              <label htmlFor="password" className="form-label">
                Your Password
              </label>
              <div className="mb-4 input-group">
                <input
                  type="password"
                  className="form-control bg-dark text-secondary"
                  name="password"
                  value={password}
                  placeholder="Your Password"
                  minLength="5"
                  onChange={onChange}
                  required
                />
                <span className="input-group-text bg-dark text-secondary">
                  <i className="bi bi-shield-lock"></i>
                </span>
              </div>

              <label htmlFor="email" className="form-label">
                Your New Email ID
              </label>
              <div className="mb-2 input-group">
                <input
                  type="email"
                  className="form-control bg-dark text-secondary"
                  name="email"
                  value={email}
                  placeholder="Ex: email@domain.name"
                  onChange={onChange}
                  required
                />
                <span className="input-group-text bg-dark text-secondary">
                  <i className="bi bi-envelope"></i>
                </span>
              </div>
            </div>

            <div className="modal-footer py-3">
              <div className="d-grid gap-2 w-100">
                <button
                  type="button"
                  className="btn btn-outline-warning"
                  disabled={loading}
                  onClick={onSubmit}
                >
                  {!loading ? (
                    "Change Email ID"
                  ) : (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">
                            Loading... Please Wait...
                          </span>
                        </div>
                        <span className="margin-l-_5">
                          Loading... Please Wait...
                        </span>
                      </div>
                    </>
                  )}
                </button>

                <button
                  type="button"
                  ref={modal}
                  className="btn btn-outline-secondary"
                  disabled={loading}
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordModal;
