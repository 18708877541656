import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Components
import AdminBase from "../AdminBase";
import Spinner from "../../Layouts/Spinner";
import DeleteUserModal from "./DeleteUserModal";
import UserAdminModal from "./UserAdminModal";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDelete, setUserDelete] = useState(null);
  const [userAdmin, setUserAdmin] = useState(null);
  const [search, setSearch] = useState("");
  const [searchFiltered, setSearchFiltered] = useState([]);

  const deleteModal = useRef();
  const userAdminModal = useRef();

  useEffect(() => {
    preload();
  }, []);

  // Preload Users Data
  const preload = async () => {
    setUsers([]);
    setLoading(true);
    try {
      const res = await axios.get("/api/user/all");
      setUsers(res.data);
      setSearchFiltered(res.data);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  // Handle Search
  const handleSearch = (e) => {
    setSearch(e.target.value);

    const searchText = e.target.value;

    if (searchText !== "" && users.length > 0) {
      const regexp = new RegExp(searchText, "gi");
      const temp = users.filter((u) => {
        return (
          u.firstName.match(regexp) ||
          u.lastName.match(regexp) ||
          u.email.match(regexp)
        );
      });
      setSearchFiltered(temp);
      //
    } else if (searchText === "") {
      setSearchFiltered(users);
    }
  };

  // Handle User Delete
  const handleDelete = async (e) => {
    setLoading(true);

    try {
      const res = await axios.delete(`/api/user/${userDelete._id}`);

      const tempObj = users.filter((u) => {
        return u._id !== res.data.user._id;
      });

      setUsers(tempObj);
      setSearchFiltered(tempObj);

      setLoading(false);
      toast.success(res.data.msg);
      deleteModal.current.click();
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      deleteModal.current.click();
    }
  };

  // Handle User's Admin Role Change
  const handleAdminChange = async (e) => {
    setLoading(true);
    try {
      const res = await axios.patch(`/api/user/role/${userAdmin._id}`);

      const newUsersObj = users.map((u) => {
        if (u._id === res.data.user._id) {
          return res.data.user;
        } else {
          return u;
        }
      });

      setUsers(newUsersObj);
      setSearchFiltered(newUsersObj);

      setLoading(false);
      toast.success(res.data.msg);
      userAdminModal.current.click();
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      userAdminModal.current.click();
    }
  };

  return (
    <AdminBase>
      <div className="my-4">
        <div className="mb-4">
          <h3>User Management</h3>
        </div>

        <div className="row my-2 mb-4">
          <div className="col-12 col-sm-6 d-grid d-sm-block">
            <button className="btn btn-violet-custom" onClick={preload}>
              Refresh User Data
            </button>
          </div>
          <div className="col-12 col-sm-6 mt-3 mt-sm-0">
            <input
              type="text"
              name="search"
              className="form-control admin-search-input text-center text-sm-start"
              value={search}
              onChange={handleSearch}
              placeholder="Search..."
              autoComplete="off"
            />
          </div>
        </div>

        {loading === true && (!users || users.length < 1) ? (
          <Spinner />
        ) : users && users.length > 0 ? (
          <div className="overflow-auto">
            <table className="text-secondary table table-bordered table-dark table-hover table-responsive-md">
              <thead>
                <tr className="fs-5">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Verified?</th>
                  <th scope="col">Role</th>
                  <th scope="col" colSpan="2" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="fw-lighter fw-light">
                {searchFiltered.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center fs-4 py-3">
                      Nothing Found!
                    </td>
                  </tr>
                ) : (
                  searchFiltered.map((user, index) => (
                    <tr key={user._id}>
                      <th scope="row">{index + 1}.</th>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                      <td>{user.email}</td>
                      <td className="text-center fs-5">
                        {user.emailVerified === true ? (
                          <>
                            <i className="bi bi-check-circle text-success margin-r-_5"></i>
                            <i className="bi bi-person-check-fill text-success"></i>
                          </>
                        ) : (
                          <>
                            <i className="bi bi-x-circle text-danger margin-r-_5"></i>
                            <i className="bi bi-person-x-fill text-danger"></i>
                          </>
                        )}
                      </td>
                      <td className="text-center fs-5">
                        {user.role === 1 ? (
                          <i className="bi bi-person-lines-fill text-warning"></i>
                        ) : (
                          <i className="bi bi-person-fill text-info"></i>
                        )}
                      </td>
                      <td align="center">
                        <button
                          className="btn btn-sm btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteUserModal"
                          onClick={(e) => {
                            setUserDelete(user);
                          }}
                        >
                          <i className="bi bi-trash"></i> Delete
                        </button>
                      </td>
                      <td align="center">
                        {user.role !== 1 ? (
                          <button
                            className="btn btn-sm btn-outline-info"
                            data-bs-toggle="modal"
                            data-bs-target="#userAdminModal"
                            onClick={(e) => {
                              setUserAdmin(user);
                            }}
                          >
                            <i className="bi bi-person-lines-fill"></i> Make
                            Admin
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-outline-warning"
                            data-bs-toggle="modal"
                            data-bs-target="#userAdminModal"
                            onClick={(e) => {
                              setUserAdmin(user);
                            }}
                          >
                            <i className="bi bi-person-lines-fill"></i> Remove
                            Admin
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1 mt-5">
            <div className="alert alert-warning">
              <div className="alert-heading h3 fw-bold">
                <i className="bi bi-exclamation-triangle margin-r-_5"></i>
                <span className="">No Users Found!</span>
              </div>
              <hr />
              <p>There are no users in the Database!</p>
            </div>
          </div>
        )}
      </div>

      <DeleteUserModal
        deleteModal={deleteModal}
        user={userDelete}
        handleDelete={handleDelete}
        loading={loading}
      />
      <UserAdminModal
        userAdminModal={userAdminModal}
        user={userAdmin}
        handleAdminChange={handleAdminChange}
        loading={loading}
      />
    </AdminBase>
  );
};

export default ManageUsers;
