import { toast } from "react-toastify";

export const isOnline = () => {
  if (!window.navigator.onLine) {
    toast.dark("⛔ No Internet Connection! You are probably Offline!", {
      toastId: "customId",
    });
    return false;
  } else return true;
};
