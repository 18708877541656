import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../Layouts/Spinner";

const TodoItem = ({ todo, changeReload }) => {
  const [item, setItem] = useState(todo);
  const [loading, setLoading] = useState(false);

  const { _id, title, details, completed, targetDate, createdAt, updatedAt } =
    item;

  // Checking if target date > current date. If yes, means item is due.
  // Taking only the date from the whole string
  const currentDate = new Date().toDateString();
  const tDate = new Date(targetDate).toDateString();

  // Taking the difference
  const dateDifference = new Date(currentDate) - new Date(tDate);

  /* 
  If the Date String difference is > 0 (Means + value) then it's due.
  If it is 0, then it is current day
  If it is < 0 (Means - value), then the date is in future. 
  */
  const dueDate = dateDifference > 0 ? true : false;

  //   console.log("Current Date: ", currentDate);
  //   console.log("Target Date: ", new Date(targetDate).toDateString());
  //   console.log("Due: ", dueDate);
  //   console.log("Diff: ", new Date(currentDate) - new Date(tDate));

  // Handle Delete
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.delete(`/api/todo/${_id}`);
      toast.success(`The task has been deleted successfully!`);
      changeReload();
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  // Handle 'Mark as Complete' & 'Mark as Incomplete'
  const handleMarkComplete = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.patch(`/api/todo/${_id}`);
      setItem(res.data);
      setLoading(false);
      changeReload();
      //
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="col-sm-6 col-lg-4 col-10 offset-sm-0 offset-1 ">
      <div
        className="card text-secondary bg-custom mb-3"
        // style={{ maxWidth: "18rem" }}
      >
        <div className="card-header fw-bold fs-5">{title}</div>
        <div className="card-body">
          {/* <h5 className="card-title">Secondary card title</h5> */}
          <div className="card-text">
            <div className="">
              <span className="fw-bold fs-6">Details: </span>
              <span className="fs-6">{details != null ? details : "N/A"}</span>
            </div>

            <div className="my-2">
              <span className="fw-bold fs-6">Created At: </span>
              <span className="fs-6">
                {new Date(createdAt).toLocaleString()}
              </span>
            </div>

            <div className="my-2">
              <span className="fw-bold fs-6">Last Updated At: </span>
              <span className="fs-6">
                {new Date(updatedAt).toLocaleString()}
              </span>
            </div>

            <div className="my-2">
              <span className="fw-bold fs-6">Target Date: </span>
              <span className="fs-6">
                {new Date(targetDate).toLocaleDateString()}
              </span>
            </div>

            <div className="my-2 align-items-center">
              <span className="fw-bold fs-6">Status: </span>
              {completed ? (
                <>
                  <span className="badge bg-success text-uppercase margin-l-_5">
                    Completed
                  </span>
                  {dateDifference === 0 && (
                    <span className="badge badge-info-custom text-uppercase margin-l-_5">
                      Today's
                    </span>
                  )}
                </>
              ) : (
                <>
                  <span className="badge bg-warning text-dark text-uppercase margin-l-_5">
                    Incomplete
                  </span>
                  {dueDate === true ? (
                    <span className="badge badge-danger-custom text-uppercase margin-l-_5">
                      Due
                    </span>
                  ) : (
                    <>
                      {dateDifference === 0 ? (
                        <span className="badge badge-info-custom text-uppercase margin-l-_5">
                          Today's
                        </span>
                      ) : (
                        <span className="badge badge-success-custom text-uppercase margin-l-_5">
                          Upcoming
                        </span>
                      )}
                    </>
                  )}
                </>
              )}

              {/* {!completed && dueDate && (
                <span className="badge bg-danger text-uppercase px-2 margin-l-_5">
                  Due
                </span>
              )} */}
            </div>
          </div>
        </div>
        <div className="card-footer">
          {!completed ? (
            <>
              <div className="row">
                <div className="col d-flex">
                  <Link
                    to={`/todo/edit/${_id}`}
                    className="btn btn-sm btn-outline-info margin-r-_5"
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={handleDelete}
                  >
                    <i className="bi bi-trash"></i> Delete
                  </button>
                </div>
                {/* <div className="col-8 text-end">
            <button className="btn btn-sm btn-outline-success">
              <i className="bi bi-check-square"></i> Mark as Complete
            </button>
          </div> */}
              </div>
              <div className="row mt-2">
                <div className="col">
                  <button
                    className="btn btn-sm btn-outline-success"
                    onClick={handleMarkComplete}
                  >
                    <i className="bi bi-check-square"></i> Mark as Complete
                  </button>
                </div>
              </div>
            </>
          ) : (
            <button
              className="btn btn-sm btn-outline-warning"
              onClick={handleMarkComplete}
            >
              <i className="bi bi-check-square"></i> Mark as Incomplete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodoItem;
