import React from "react";
import Navbar from "../Layouts/Navbar";

const Error404 = () => {
  return (
    <>
      <Navbar />
      <div className="container mt-5 mb-3">
        <h1 className="display-4 text-center">Error 404!</h1>
        <div className="alert alert-danger text-center my-5 p-5">
          <h2>The page you are looking for, does not exist!</h2>
        </div>
      </div>
    </>
  );
};

export default Error404;
