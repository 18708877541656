import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// Components
import Navbar from "../../Layouts/Navbar";

const EmailVerification = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (match.params.token) {
      setLoading(true);
      verify();
    }
    // eslint-disable-next-line
  }, []);

  const verify = async () => {
    try {
      const res = await axios.post(`/api/email/verification`, {
        token: match.params.token,
      });

      // console.log(res.data.msg);
      toast.success(res.data.msg);
      setLoading(false);
      history.push("/signin");
    } catch (error) {
      // console.log(error.response);
      toast.error(
        error?.response?.data?.error?.msg || error?.response?.data?.error
      );
      setLoading(false);
      history.push("/signup");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4 text-white">
        <h1>Email Verification</h1>

        <div className="row">
          {loading && (
            <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1 mt-5">
              <div className="alert alert-info">
                <div className="alert-heading h3 fw-bold d-flex align-items-center">
                  <i className="bi bi-info-circle margin-r-_5"></i>
                  <span className="">Email Verification</span>

                  <div className="spinner-border margin-l-_5" role="status">
                    <span className="visually-hidden">Verifying...</span>
                  </div>
                </div>
                <hr />
                <p>We are verifying your email... Please Wait!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
