import React from "react";

const UserAdminModal = ({
  userAdminModal,
  user,
  handleAdminChange,
  loading,
}) => {
  return (
    <div
      className="modal fade"
      id="userAdminModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="userAdminModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-dark text-white">
          <div className="modal-header">
            <h5 className="modal-title" id="userAdminModalLabel">
              {user?.role === 1 ? "Remove" : "Make"} User {user?.firstName}{" "}
              {user?.role === 1 ? "from Admin Role" : "an Admin"}?
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={userAdminModal}
            ></button>
          </div>
          <div className="modal-body">
            <span>Are you sure ?</span>
            <p className="text-secondary my-2">
              User's Name: {user?.firstName} {user?.lastName}
              <br />
              Email: {user?.email}
              <br />
              Role: {user?.role === 1 ? "Admin" : "User"}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
              disabled={loading === true ? true : false}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-outline-warning"
              disabled={loading === true ? true : false}
              onClick={handleAdminChange}
            >
              {loading === false ? (
                user?.role === 1 ? (
                  "Remove Admin"
                ) : (
                  "Make Admin"
                )
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-sm margin-r-_5"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAdminModal;
